import React from 'react';
import { PageType } from 'interfaces/content/articles/Post';
import { AdsCenteredDiv, InStreamAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

interface SimpleInStreamProps {
  id: number;
  pageType?: PageType;
}

const SimpleInStream: React.FC<SimpleInStreamProps> = ({ id, pageType }: SimpleInStreamProps) => (
  <AdsCenteredDiv>
    <InStreamAd
      className='simple'
      $pageType={pageType}
    >
      <AdSlotToggle
        placementName='SimpleInStream'
        index={id + 1}
        slotId={`SimpleInStream${id}`}
      />
    </InStreamAd>
  </AdsCenteredDiv>
);

export default SimpleInStream;
