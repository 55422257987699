import { MenuItemProps } from 'interfaces/navigation/Header';

export interface MenuItemResponse {
  id: string;
  label: string;
  url: string;
  parentId: string | null;
}

interface MenuNodes {
  nodes: {
    menuItems: {
      nodes: MenuItemResponse[];
      pageInfo?: {
        endCursor: string;
        hasNextPage: boolean;
      };
    };
  }[];
}

export interface MenuResponse {
  rightMenu: MenuNodes;
  rightMenu2: MenuNodes;
  rightRailMenu: MenuNodes;
  leftMenu: MenuNodes;
  topicNavigation: MenuNodes;
}

function findRecursive(menuItems: MenuItemProps[], id: string): MenuItemProps | null {
  let res = null;

  menuItems.forEach((menuItem) => {
    if (menuItem.id === id) {
      res = menuItem;
    } else if (menuItem.subMenu) {
      res = findRecursive(menuItem.subMenu, id);
    }
  });

  return res;
}

function buildMenuItemTree(menuItems: MenuItemResponse[]) {
  const result: MenuItemProps[] = [];

  menuItems.forEach((menuItem) => {
    if (menuItem.parentId === null) {
      result.push({
        id: menuItem.id,
        label: menuItem.label,
        subMenu: null,
        url: menuItem.url,
      });
    } else {
      const parent = findRecursive(result, menuItem.parentId);
      if (!parent) {
        throw new Error(`Parent menu item not found for ${menuItem.id}`);
      }
      if (!parent.subMenu) {
        parent.subMenu = [];
      }
      parent.subMenu.push({
        id: menuItem.id,
        label: menuItem.label,
        subMenu: null,
        url: menuItem.url,
      });
    }
  });

  return result;
}

export default buildMenuItemTree;
